<script setup lang="ts">
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import Logo from '@/assets/images/logo-naboo-bleu.svg'
import { default as PButton } from 'primevue/button'
import { useAuthStore } from '@/stores/auth'
import { ref } from 'vue'
import FirstForm from '@/views/login/blocks/FirstForm.vue'
import ConfirmEmailForm from '@/views/login/blocks/ConfirmEmailForm.vue'
import { useSignupStore } from '@/stores/signupStore'
import { storeToRefs } from 'pinia'
import type { ISignupEmailInput, ISignupLookupInput } from '@/assets/ts/DTO/signup/signup.dto'

const { loginWithRedirect } = useAuthStore()

const step = ref(1)
const form = ref({
  schoolCode: '',
  firstname: '',
  lastname: ''
})

const formEmail = ref({
  identifier: '',
  email: '',
  confirmEmail: '',
  appCode: ''
})

const { lookupUser, confirmEmail } = useSignupStore()
const { hasError, linkToRenewPassword } = storeToRefs(useSignupStore())

async function handleValidate() {
  if (step.value === 1) {
    await lookupUser(form.value as ISignupLookupInput)
    if (hasError.value) return
    step.value += 1
  } else if (step.value === 2) {
    await confirmEmail(formEmail.value as ISignupEmailInput)
    if (hasError.value) return
    step.value += 1
  }
}

function redirectUser() {
  if (step.value === 3 && !hasError.value) {
    window.location.href = linkToRenewPassword.value
  }
}
</script>

<template>
  <div
    class="h-full w-full flex flex-col overflow-auto justify-center items-center p-4"
    :class="$style['VLanding']"
  >
    <b-orbits :with-planet="true" />

    <div
      class="max-w-96 w-full bg-white rounded-lg shadow-2xl p-8 flex flex-col items-center gap-4 mx-auto relative"
    >
      <img :src="Logo" alt="Logo" class="w-32" />

      <first-form v-model:form="form" v-if="step === 1" @validate="handleValidate" />

      <confirm-email-form v-model:form="formEmail" v-if="step === 2" @validate="handleValidate" />

      <template v-if="step === 3">
        <p class="text-primary text-center">Félicitations, ton compte a été activé !</p>

        <p-button label="Démarrer l'aventure" rounded severity="primary" @click="redirectUser" />
      </template>

      <a
        v-if="step !== 3"
        class="text-purple-500 underline text small cursor-pointer"
        @click="() => loginWithRedirect()"
        >Je déjà un compte
      </a>
    </div>
  </div>
</template>

<style module>
.VLanding {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
