import { type NavigationGuardNext, type RouteLocationNormalized, type Router } from 'vue-router'
import { storeToRefs } from 'pinia'
import slug from 'slug'
import { useEMethodosStore } from '@/stores/emethodos'
import { useHistoryStore } from '@/stores/navigationHistory'

export const clearQueryParamsVersion = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.query.v) {
    const query = { ...to.query }
    delete query.v

    // Vérifie si le paramètre `v` a déjà été supprimé pour éviter une boucle
    if (to.query.v) {
      next({
        name: to.name as string,
        params: to.params,
        query: query,
        hash: to.hash,
        replace: true // Utilise replace pour ne pas ajouter à l'historique
      })
      return
    }
  }

  // Continue normalement si `v` n'est pas dans les query
  next()
}

export const updateUrlPath = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { getVideo } = storeToRefs(useEMethodosStore())

  if (to.name === 'video' && getVideo.value?.title && !to.params?.videoTitle) {
    next({
      name: 'video',
      params: {
        videoTitle: slug(getVideo.value.title || '')
      }
    })
    return
  }

  next()
}

/**
 * Used to update the current url path when the user is completing a training session or grain
 */
export const updateCurrentUrlPath = async (router: Router, route: RouteLocationNormalized) => {
  const { getVideo } = storeToRefs(useEMethodosStore())

  if (route.name === 'video' && getVideo.value?.title && !route.params.videoTitle) {
    await router.replace({
      name: 'video',
      params: {
        videoTitle: slug(getVideo.value.title || '')
      }
    })
  }
}

export const isFeatureEnabled = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { isEnabled } = storeToRefs(useEMethodosStore())

  if (!isEnabled.value) {
    next({ name: 'logbook' })
    return
  }
  next()
}

export const registerRouteToHistory = (route: RouteLocationNormalized) => {
  const { addToHistory } = useHistoryStore()

  if (route.name === 'quiz') return
  if (route.name === 'task') return
  addToHistory(route.fullPath)
}
