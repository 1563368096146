<script setup lang="ts">
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import Logo from '@/assets/images/logo-naboo-bleu.svg'
import { default as PButton } from 'primevue/button'
import { useAuthStore } from '@/stores/auth'

const { loginWithRedirect } = useAuthStore()
</script>

<template>
  <div
    class="h-full w-full flex flex-col overflow-auto justify-center items-center"
    :class="$style['VLanding']"
  >
    <b-orbits :with-planet="true" />

    <div
      class="max-w-80 w-full bg-white rounded-lg shadow-2xl p-8 flex flex-col items-center gap-4 mx-auto relative"
    >
      <img :src="Logo" alt="Logo" class="w-32" />
      <h1 class="brand-heading text-primary">Bienvenue</h1>
      <p class="text-center text-primary">
        Jeune explorateur, <span class="font-bold">bienvenue</span> au sein du
        <span class="font-bold">programme d’exploration spatiale Naboo !</span>
      </p>

      <p-button label="Connexion" rounded severity="primary" @click="loginWithRedirect" />

      <a
        class="text-purple-500 underline text small cursor-pointer"
        @click="$router.push({ name: 'register' })"
        >Je n'ai pas de compte</a
      >
    </div>
  </div>
</template>

<style module>
.VLanding {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
