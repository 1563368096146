import { clearQueryParamsVersion, isFeatureEnabled, updateUrlPath } from '@/router/utils'
import { createAuthGuard } from '@auth0/auth0-vue'
import VContentsParent from '@/views/emethodos/VContentsParent.vue'
import VContents from '@/views/emethodos/VContents.vue'
import VEmethodo from '@/views/emethodos/VEmethodo.vue'
import VCgu from '@/views/infos/VCgu.vue'
import VQuiz from '@/views/quiz/VQuiz.vue'
import VLogbook from '@/views/logbook/VLogbook.vue'
import VMission from '@/views/training/VMission.vue'
import VOnboarding from '@/views/onboarding/VOnboarding.vue'
import VResults from '@/views/results/VResults.vue'
import VMissionResult from '@/views/results/VMissionResult.vue'
import VProgram from '@/views/training/VProgram.vue'
import VTask from '@/views/training/VTask.vue'
import VLanding from '@/views/landing/VLanding.vue'
import VRegister from '@/views/login/VRegister.vue'

/**
 * Auth0 guard
 */
const authGuard = createAuthGuard()

export const routes = [
  {
    path: '',
    name: 'home',
    component: VLanding,
    meta: {
      hideBottomMenu: true
    },
    beforeEnter: [clearQueryParamsVersion]
  },
  {
    path: '/inscription',
    name: 'register',
    component: VRegister,
    meta: {
      title: 'Inscription',
      hideBottomMenu: true
    },
    beforeEnter: [clearQueryParamsVersion]
  },
  {
    path: '/journal-de-bord',
    name: 'logbook',
    component: VLogbook,
    meta: {
      title: 'Mon journal de bord'
    },
    beforeEnter: [authGuard, clearQueryParamsVersion]
  },
  {
    path: '/les-tutos',
    name: 'emethodos',
    component: VContentsParent,
    meta: {
      title: 'Contenus'
    },
    children: [
      {
        path: '',
        name: 'contents',
        component: VContents,
        meta: {
          title: 'Contenus'
        },
        beforeEnter: [clearQueryParamsVersion, isFeatureEnabled]
      },
      {
        path: './:videoTitle?',
        name: 'video',
        component: VEmethodo,
        meta: {
          title: 'Contenus'
        },
        beforeEnter: [clearQueryParamsVersion, updateUrlPath, isFeatureEnabled]
      }
    ],
    beforeEnter: [authGuard, clearQueryParamsVersion]
  },
  {
    path: '/cgu',
    name: 'terms-of-use',
    component: VCgu,
    meta: {
      title: "Conditions générales d'utilisation",
      hideHeader: true
    }
  },
  {
    path: '/test-d-entree',
    name: 'diagnostic',
    component: VQuiz,
    meta: {
      title: "Test d'entrée de la mission Naboo",
      hideHeader: true,
      hideBottomMenu: true
    },
    beforeEnter: [authGuard, clearQueryParamsVersion]
  },
  {
    path: '/bienvenue',
    name: 'welcome',
    component: VOnboarding,
    meta: {
      title: 'Bienvenue',
      hideHeader: true,
      hideBottomMenu: true
    },
    beforeEnter: [authGuard, clearQueryParamsVersion]
  },
  {
    path: '/programme',
    children: [
      {
        path: '',
        name: 'program',
        component: VProgram,
        meta: {
          title: 'le programme Naboo'
        },
        beforeEnter: [authGuard, clearQueryParamsVersion]
      },
      {
        path: ':courseId/mission/:moduleId',
        name: 'mission',
        component: VMission,
        meta: {
          title: 'Mission Naboo'
        },
        beforeEnter: [authGuard, clearQueryParamsVersion]
      },
      {
        path: ':courseId/mission/:moduleId/quiz/:quizId',
        name: 'quiz',
        component: VQuiz,
        meta: {
          title: 'Quiz',
          hideBottomMenu: true
        },
        beforeEnter: [authGuard, clearQueryParamsVersion]
      },
      {
        path: ':courseId/mission/:moduleId/task/:grainId',
        name: 'task',
        component: VTask,
        meta: {
          hideBottomMenu: true
        },
        beforeEnter: [authGuard, clearQueryParamsVersion]
      }
    ]
  },
  {
    path: '/rapport-d-activite',
    children: [
      {
        path: '',
        name: 'activityReport',
        component: VResults,
        meta: {
          title: "Rapport d'activité"
        },
        beforeEnter: [authGuard, clearQueryParamsVersion]
      },
      {
        path: 'mission/:missionId',
        name: 'missionResult',
        component: VMissionResult,
        meta: {
          title: "Rapport d'activité"
        },
        beforeEnter: [authGuard, clearQueryParamsVersion]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*', // Route catch-all
    name: 'NotFound',
    redirect: '/'
  }
]
