import { profile } from '@/services/API/profile/profile'
import { emethodos } from '@/services/API/EMethodos'
import { logbook } from '@/services/API/profile/logbook'
import { quiz } from '@/services/API/profile/quiz'
import { xAPI } from '@/services/API/xAPI'
import { program } from '@/services/API/training/program'
import { signup } from '@/services/API/signup'

const modules = {
  ...profile,
  ...emethodos,
  ...logbook,
  ...quiz,
  ...xAPI,
  ...program,
  ...signup
}

export default modules
