import API from '@/services/API'
import {
  type ISignupEmailInput,
  type ISignupLookupInput,
  LinkResponseDto
} from '@/assets/ts/DTO/signup/signup.dto'
import { GetMeResponseDto } from '@/assets/ts/DTO/profile/profile.response.dto'
import { NabooError } from '@/assets/ts/classes/Error'

export const signup = {
  /**
   * Try to find a user by school code, firstname and lastname
   */
  async lookupForSignup(payload: ISignupLookupInput) {
    try {
      const response = await API().post('signup/lookup', payload)
      return Promise.resolve(new GetMeResponseDto(response.data))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/signup/lookup - lookupForSignup')
      }
      return Promise.reject(error)
    }
  },

  /**
   * Confirm the email of a user and return a link to renew the password
   */
  async confirmEmailForSignup(payload: ISignupEmailInput) {
    try {
      const response = await API().post('signup/confirm-email', payload)
      return Promise.resolve(new LinkResponseDto(response.data))
    } catch (error) {
      if (error instanceof NabooError) {
        error.setStack('API/signup/confirm-email - confirmEmailForSignup')
      }
      return Promise.reject(error)
    }
  }
}
