import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'

export const useLoadingStore = defineStore('loading', () => {
  const isLoading = useLocalStorage('loading', ref(false))

  // ACTIONS
  function $reset() {
    isLoading.value = false
  }

  function startLoading() {
    isLoading.value = true
  }

  function stopLoading() {
    isLoading.value = false
  }

  return { $reset, isLoading, startLoading, stopLoading }
})
