<script setup lang="ts">
import { default as PCheckbox } from 'primevue/checkbox'
import { default as PButton } from 'primevue/button'
import { computed, type Ref, ref } from 'vue'
import type { IMCQAnswer } from '@/assets/ts/types/learning/answers'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'

const emit = defineEmits(['answer', 'next'])

const props = defineProps<{
  answersContent: IMCQAnswer[]
  showCorrectAnswers: boolean
}>()

const answers: Ref<string[]> = ref([])

const getCorrectAnswers = computed(() => {
  return props.answersContent.filter((answer) => answer.isCorrect).map((answer) => answer.label)
})

const areSelectedAnswersCorrect = computed(() => {
  return (
    getCorrectAnswers.value.every((answer) => getAnswersSelected.value.includes(answer)) &&
    getAnswersSelected.value.length === getCorrectAnswers.value.length
  )
})

const getAnswersSelected = computed(() => {
  return props.answersContent
    .filter((answer) => answers.value.includes(answer.label))
    .map((answer) => answer.label)
})

const handleAnswer = () => {
  if (props.showCorrectAnswers) {
    emit('next')
    return
  }

  let userAnswer = {} as IUserAnswer

  userAnswer.answers = getAnswersSelected.value
  userAnswer.isCorrect = areSelectedAnswersCorrect.value

  if (
    getCorrectAnswers.value.every((answer) => getAnswersSelected.value.includes(answer)) &&
    getAnswersSelected.value.length < getCorrectAnswers.value.length
  ) {
    userAnswer.feedbackLabel = 'Il en manque !'
  }

  emit('answer', userAnswer)
}
</script>

<template>
  <form class="grid grid-cols-1 gap-4 w-full auto-rows-[minmax(min-content,1fr)] answers">
    <div v-for="(answer, i) in answersContent" class="flex items-center gap-2" :key="'answer_' + i">
      <p-checkbox
        :disabled="showCorrectAnswers"
        :value="answer.label"
        :inputId="answer.label"
        v-model="answers"
      />
      <label
        class="text bg-surface-blue rounded-lg p-4 flex-grow h-full flex items-center min-h-24 gap-2 justify-between"
        :class="{
          'border-2 border-solid': showCorrectAnswers && getAnswersSelected.includes(answer.label),
          'border-green-500':
            showCorrectAnswers && answer.isCorrect && getAnswersSelected.includes(answer.label),
          'border-red-500': getAnswersSelected.includes(answer.label) && !answer.isCorrect,
          'opacity-50': showCorrectAnswers && !getAnswersSelected.includes(answer.label)
        }"
        :for="answer.label"
        >{{ answer.label }}
        <i
          v-if="
            showCorrectAnswers && (answer.isCorrect || getAnswersSelected.includes(answer.label))
          "
          class="pi font-bold text-xl"
          :class="{
            'pi-check text-green-500':
              getAnswersSelected.includes(answer.label) && answer.isCorrect,
            'pi-times text-red-500': getAnswersSelected.includes(answer.label) && !answer.isCorrect
          }"
        />
      </label>
    </div>
  </form>

  <div class="fixed bottom-0 w-full flex justify-center p-8" :class="$style.QuizFooter">
    <p-button
      :label="showCorrectAnswers ? 'Question suivante' : 'Valider'"
      severity="light"
      rounded
      class="mt-auto"
      @click="handleAnswer"
      :outlined="answers.length === 0"
      :disabled="answers.length === 0"
    />
  </div>
</template>

<style module>
.QuizFooter {
  background: linear-gradient(180deg, transparent 0%, var(--surface-blue-500) 40%);
}
</style>
