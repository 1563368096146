/**
 * Interface for the signup lookup input.
 */
export interface ISignupLookupInput {
  schoolCode: string
  firstname: string
  lastname: string
}

/**
 * Interface for the signup confirm email input.
 */
export interface ISignupEmailInput {
  identifier: string
  email: string
  confirmEmail: string
  appCode: string
}

/**
 * Generic interface for a link response.
 * Mapping of LinkResponseInfo from API.
 */
export interface ILinkResponse {
  link: string
}

/**
 * DTO for a link response.
 */
export class LinkResponseDto {
  link: string

  constructor(payload: ILinkResponse) {
    this.link = payload.link
  }
}
