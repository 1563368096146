<script lang="ts" setup>
import { default as PButton } from 'primevue/button'
import EIcons from '@/components/elements/icons/EIcons.vue'
import EIcon from '@/components/elements/icons/EIcon.vue'
import MOverlayProfile from '@/components/modules/overlays/MOverlayProfile.vue'
import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'

const { displayProfileOverlay } = storeToRefs(useProfileStore())

const items = [
  {
    title: 'Journal de bord',
    icon: 'DiaryBook',
    route: { name: 'logbook' }
  },
  {
    title: 'Programme',
    icon: 'Layers',
    route: { name: 'program' }
  },
  {
    title: 'Ressources',
    icon: 'Playlist',
    route: { name: 'contents' }
  }
  // {
  //   title: "Rapport d'activité",
  //   icon: 'Star',
  //   route: { name: 'activityReport' }
  // }
]
</script>

<template>
  <e-icons />

  <div :class="$style.MBottomMenu">
    <div v-for="(item, i) in items" :key="i">
      <p-button
        severity="light"
        text
        @click="() => $router.push(item.route)"
        class="flex flex-col p-1"
        :class="[$style.MBottomMenuButton, $route.name === item.route.name ? $style.active : '']"
      >
        <i style="width: 1.5rem; height: 1.5rem">
          <e-icon :name="item.icon" type="svg" />
        </i>

        <span class="uppercase" :class="$style.MBottomMenuButtonLabel">{{ item.title }}</span>
      </p-button>
    </div>
  </div>

  <m-overlay-profile v-if="displayProfileOverlay" />
</template>

<style module>
.MBottomMenu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--primary-night-blue);
  padding: 0.5rem;
  bottom: 0;
  width: 100%;
  z-index: 10;
  gap: 0.5rem;

  .MBottomMenuButton {
    --fill: var(--secondary);
    &.active {
      --fill: var(--variant-white);
      color: var(--variant-white);
    }

    .MBottomMenuButtonLabel {
      font-size: 0.5rem;
      font-weight: 400;
      white-space: nowrap;
      color: var(--secondary);
    }
  }
}
</style>
