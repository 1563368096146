import { defineStore, storeToRefs } from 'pinia'
import { type Ref, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import type { IProgressQuestion, IQuiz } from '@/assets/ts/types/progress/Quiz'
import { Diagnostic } from '@/assets/ts/classes/progress/diagnostic'
import { xAPIMessageBuilder } from '@/assets/ts/classes/xApi'
import { useProfileStore } from '@/stores/profile'
import type { IXAPIStatementType, UUID } from '@/assets/ts/types/xAPI/xAPI'
import { v4 as uuidv4 } from 'uuid'
import { useAppStore } from '@/stores/app'
import { usexAPIStore } from '@/stores/xAPI'
import { Quiz } from '@/assets/ts/classes/progress/quiz'
import type {
  IMatchingQuestion,
  IMCQQuestion,
  IQCUQuestion,
  ISequencingQuestion,
  ITrueFalseQuestion
} from '@/assets/ts/types/learning/QuizQuestion'
import { LearningEnums } from '@/assets/ts/types/learning/enums'
import type { IUserAnswer } from '@/assets/ts/types/learning/learningQuiz'

export const useQuizStore = defineStore('quiz', () => {
  const quizzes: Ref<IQuiz[]> = ref([])
  const diagnostic: Ref<Diagnostic> = ref({} as Diagnostic)
  const { me } = storeToRefs(useProfileStore())
  const { registrationCtx, appType } = storeToRefs(useAppStore())
  const { addToQueue } = usexAPIStore()

  function addQuizToList(quiz: IQuiz) {
    quizzes.value.push(quiz)
  }

  async function getQuizFromId(quizId: number) {
    try {
      const quiz = new Quiz(await nabooApi.getQuiz(quizId))
      addQuizToList(quiz)
      return Promise.resolve(quiz)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function getQuizFromCourseSlug(courseSlug: string) {
    try {
      const diag = new Diagnostic(await nabooApi.getQuizzesFromCourse(courseSlug))

      quizzes.value = diag.quizzes
      diagnostic.value = diag
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function quizById(quizId: number) {
    try {
      const quiz = await nabooApi.getQuiz(quizId)
      return Promise.resolve(quiz)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function startQuiz(quiz: Quiz, grainId?: number) {
    const input: {
      quizType: LearningEnums.QuizType
      actorUUID: UUID
      contextRegistrationUUID: UUID
      courseId: string
      moduleId?: string
      grainId?: string
      quizId: string
      attemptSessionUUID: UUID
      platform: 'web' | 'pwa'
      language: string
    } = {
      quizType: quiz.quizType,
      actorUUID: me.value.identifier as UUID,
      contextRegistrationUUID: registrationCtx.value,
      courseId: quiz.courseId.toString(),
      moduleId: quiz.moduleId?.toString(),
      grainId: grainId?.toString(),
      quizId: quiz.quizId.toString(),
      attemptSessionUUID: uuidv4(),
      platform: appType.value,
      language: 'fr-FR'
    }

    const xAPIMessage = xAPIMessageBuilder.createAttemptedQuizMessage(input)
    quiz.startQuiz(xAPIMessage.timestamp, input.attemptSessionUUID)

    await addToQueue(xAPIMessage)
  }

  async function sendAnsweredQuestion(
    quiz: Quiz,
    question: IProgressQuestion,
    userAnswer: IUserAnswer,
    duration: string,
    grainId?: number
  ) {
    let xAPIMessage: IXAPIStatementType
    const commonInput = {
      actorUUID: me.value.identifier as UUID,
      contextRegistrationUUID: registrationCtx.value,
      courseId: quiz.courseId.toString(),
      moduleId: quiz.moduleId ? quiz.moduleId.toString() : null,
      grainId: grainId?.toString(),
      quizId: quiz.quizId.toString(),
      quizType: quiz.quizType,
      questionId: question.questionId.toString(),
      questionLabel: question.label,
      attemptSessionUUID: quiz.uuidAttemptSession as UUID,
      platform: appType.value as 'web' | 'pwa',
      language: 'fr-FR',
      response: userAnswer.answers,
      duration,
      success: userAnswer.isCorrect,
      score: userAnswer.isCorrect ? 1 : 0,
      maxScore: question.scoreMax,
      minScore: question.scoreMin
    }

    if (question.type === LearningEnums.QuestionType.TRUE_FALSE) {
      const trueFalseQuestion = question as ITrueFalseQuestion
      xAPIMessage = xAPIMessageBuilder.createAnsweredQuestionMessage({
        ...commonInput,
        interactionType: 'true-false',
        correctResponsesPattern: trueFalseQuestion.answers
          .filter((answer) => answer.isCorrect)
          .map((answer) => answer.label)
      })
      quiz.answerQuestion(question.questionId, userAnswer.isCorrect ? 1 : 0, xAPIMessage.id)

      await addToQueue(xAPIMessage)
      return
    } else if (question.type === LearningEnums.QuestionType.SINGLE_CHOICE) {
      const singleChoiceQuestion = question as IQCUQuestion
      xAPIMessage = xAPIMessageBuilder.createAnsweredQuestionMessage({
        ...commonInput,
        interactionType: 'choice',
        correctResponsesPattern: singleChoiceQuestion.answers
          .filter((answer) => answer.isCorrect)
          .map((answer) => answer.label),
        choices: singleChoiceQuestion.answers.map((answer, i) => ({
          id: answer.label,
          description: { 'fr-FR': answer.label }
        }))
      })
      quiz.answerQuestion(question.questionId, userAnswer.isCorrect ? 1 : 0, xAPIMessage.id)

      await addToQueue(xAPIMessage)
      return
    } else if (question.type === LearningEnums.QuestionType.MULTIPLE_CHOICE) {
      const multipleChoiceQuestion = question as IMCQQuestion
      xAPIMessage = xAPIMessageBuilder.createAnsweredQuestionMessage({
        ...commonInput,
        interactionType: 'choice',
        correctResponsesPattern: multipleChoiceQuestion.answers
          .filter((answer) => answer.isCorrect)
          .map((answer) => answer.label),
        choices: multipleChoiceQuestion.answers.map((answer) => ({
          id: answer.label,
          description: { 'fr-FR': answer.label }
        }))
      })
      quiz.answerQuestion(question.questionId, userAnswer.isCorrect ? 1 : 0, xAPIMessage.id)

      await addToQueue(xAPIMessage)
      return
    } else if (question.type === LearningEnums.QuestionType.MATCHING) {
      const matchingQuestion = question as IMatchingQuestion
      xAPIMessage = xAPIMessageBuilder.createAnsweredQuestionMessage({
        ...commonInput,
        interactionType: 'matching',
        correctResponsesPattern: matchingQuestion.answers.map((answer) =>
          [answer.left.value, answer.right.value].join('[,]')
        ),
        source: matchingQuestion.answers.map((answer) => ({
          id: answer.left,
          description: { 'fr-FR': answer.left }
        })),
        target: matchingQuestion.answers.map((answer) => ({
          id: answer.right,
          description: { 'fr-FR': answer.right }
        }))
      })
      quiz.answerQuestion(question.questionId, userAnswer.isCorrect ? 1 : 0, xAPIMessage.id)

      await addToQueue(xAPIMessage)
      return
    } else if (question.type === LearningEnums.QuestionType.SEQUENCING) {
      const sequencingQuestion = question as ISequencingQuestion
      xAPIMessage = xAPIMessageBuilder.createAnsweredQuestionMessage({
        ...commonInput,
        interactionType: 'sequencing',
        correctResponsesPattern: sequencingQuestion.answers.map((answer) => answer.data.value),
        choices: sequencingQuestion.answers.map((answer) => ({
          id: answer.data.value,
          description: { 'fr-FR': answer.data.value }
        }))
      })
      quiz.answerQuestion(question.questionId, userAnswer.isCorrect ? 1 : 0, xAPIMessage.id)

      await addToQueue(xAPIMessage)
      return
    }
  }

  async function sendCompletedQuiz(quiz: Quiz, grainId?: number) {
    const input: {
      actorUUID: UUID
      contextRegistrationUUID: UUID
      courseId: string
      moduleId: string | null
      quizId: string
      quizType: LearningEnums.QuizType
      grainId?: string
      attemptSessionUUID: UUID
      platform: 'web' | 'pwa'
      language: string
      score: number
      maxScore: number
      minScore: number | null
      duration: string
    } = {
      actorUUID: me.value.identifier as UUID,
      contextRegistrationUUID: registrationCtx.value,
      courseId: quiz.courseId.toString(),
      moduleId: quiz.moduleId ? quiz.moduleId.toString() : null,
      quizId: quiz.quizId.toString(),
      quizType: quiz.quizType,
      grainId: grainId?.toString(),
      attemptSessionUUID: quiz.uuidAttemptSession as UUID,
      platform: appType.value,
      language: 'fr-FR',
      score: quiz.score as number,
      maxScore: quiz.questions.length,
      minScore: quiz.scoreMin,
      duration: quiz.duration.iso8601 as string
    }

    const xAPIMessage = xAPIMessageBuilder.createCompletedQuizMessage(input)
    await addToQueue(xAPIMessage)
    return
  }

  return {
    quizzes,
    diagnostic,
    quizById,
    addQuizToList,
    getQuizFromCourseSlug,
    getQuizFromId,
    startQuiz,
    sendAnsweredQuestion,
    sendCompletedQuiz
  }
})
