<script setup lang="ts">
import QuizHeader from '@/views/quiz/blocks/QuizHeader.vue'
import { onMounted, ref, useCssModule } from 'vue'
import QuizIntro from '@/views/quiz/blocks/QuizIntro.vue'
import { useQuizStore } from '@/stores/quiz'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import type { Quiz } from '@/assets/ts/classes/progress/quiz'
import QuizThemeIntro from '@/views/quiz/blocks/QuizThemeIntro.vue'
import QuizContent from '@/views/quiz/blocks/QuizContent.vue'
import QuizzesResults from '@/views/quiz/blocks/QuizzesResults.vue'
import { useProgramStore } from '@/stores/program'
import QuizGrainIntro from '@/views/quiz/blocks/QuizPreGrainIntro.vue'
import { LearningEnums } from '@/assets/ts/types/learning/enums'
import { Task } from '@/assets/ts/classes/progress/task'
import QuizGrainEnd from '@/views/quiz/blocks/QuizGrainEnd.vue'
import QuizPreGrainIntro from '@/views/quiz/blocks/QuizPreGrainIntro.vue'
import QuizPostGrainIntro from '@/views/quiz/blocks/QuizPostGrainIntro.vue'
import QuizPostGrainTaskResult from '@/views/quiz/blocks/QuizPostGrainTaskResult.vue'

// VARIABLES
const loading = ref(true)
const expandedHeader = ref(true)
const showIntro = ref(false)
const style = useCssModule()
const route = useRoute()
const router = useRouter()
const { mission } = storeToRefs(useProgramStore())
const { diagnostic } = storeToRefs(useQuizStore())
const quizToAnswer = ref({} as Quiz)
const showThemeIntro = ref(false)
const showDiagResults = ref(false)
const showEndGrainQuiz = ref(false)
const showTaskResult = ref(false)
const quizTitle = ref('')

// HOOKS
onMounted(async () => {
  if (route.name === 'diagnostic') {
    await handleMountedForDiagnostic()
  }

  if (route.name === 'quiz') {
    await handleMountedForQuiz()
  }

  loading.value = false
})

// METHODS
const { getQuizFromCourseSlug, getQuizFromId, startQuiz } = useQuizStore()
const { getMission, startTask, completeTask } = useProgramStore()

const handleMountedForDiagnostic = async () => {
  await getQuizFromCourseSlug(route.query.exploration as string)

  quizTitle.value = "Test d'entrée"

  if (diagnostic.value.quizzesToAttempt.length === 0) {
    expandedHeader.value = false
    showDiagResults.value = true
    loading.value = false
    return
  }

  if (diagnostic.value.isStarted) {
    expandedHeader.value = false
    showIntro.value = false
    quizToAnswer.value = diagnostic.value.quizzesToAttempt[0]
    if (!quizToAnswer.value.isQuizStarted) {
      showThemeIntro.value = true
    }
  } else {
    expandedHeader.value = true
    setTimeout(() => {
      expandedHeader.value = false

      setTimeout(() => {
        showIntro.value = true
      }, 300)
    }, 500)
  }
}

const handleMountedForQuiz = async () => {
  quizToAnswer.value = await getQuizFromId(Number(route.params.quizId))

  if (mission.value?.moduleId !== Number(route.params.moduleId)) {
    await getMission(Number(route.params.courseId), Number(route.params.moduleId))
  }

  quizTitle.value = mission.value.getTaskByQuizId(Number(route.params.quizId)).title
  expandedHeader.value = false
  showThemeIntro.value = true
  showTaskResult.value = false
}

const start = () => {
  showIntro.value = false
  showThemeIntro.value = true
  quizToAnswer.value = diagnostic.value.quizzesToAttempt[0]
}

const startDiagnosticQuiz = (quiz: Quiz) => {
  startQuiz(quiz)
  showThemeIntro.value = false
}

const startGrainQuiz = async () => {
  if (quizToAnswer.value.quizType === LearningEnums.QuizType.PRE) {
    await startTask(
      Number(route.params.courseId),
      mission.value,
      mission.value.getTaskByQuizId(quizToAnswer.value.quizId)
    )
    await startQuiz(
      quizToAnswer.value,
      mission.value.getTaskByQuizId(quizToAnswer.value.quizId).grainId
    )
  } else {
    await startQuiz(
      quizToAnswer.value,
      mission.value.getTaskByQuizId(quizToAnswer.value.quizId).grainId
    )
  }

  showThemeIntro.value = false
}

const handleEndQuiz = () => {
  if (quizToAnswer.value.quizType === LearningEnums.QuizType.DIAG) {
    if (diagnostic.value.quizzesToAtemptCount > 0) {
      quizToAnswer.value = diagnostic.value.quizzesToAttempt[0]
      showThemeIntro.value = true
    } else {
      showDiagResults.value = true
    }
  } else {
    if (quizToAnswer.value.quizType === LearningEnums.QuizType.PRE) {
      showEndGrainQuiz.value = true
    } else {
      if (!mission.value.getTaskByQuizId(quizToAnswer.value.quizId).isCompleted)
        completeTask(
          Number(route.params.courseId),
          mission.value,
          mission.value.getTaskByQuizId(quizToAnswer.value.quizId)
        )
      showDiagResults.value = true
      showTaskResult.value = true
    }
  }
}

const handleNextTaskQuiz = async (nextTask: Task) => {
  await router.push({
    name: 'quiz',
    params: {
      courseId: route.params.courseId,
      moduleId: route.params.moduleId,
      quizId: nextTask.preQuizId
    }
  })

  loading.value = true
  await handleMountedForQuiz()
  loading.value = false
}
</script>

<template>
  <div class="h-full w-full flex flex-col overflow-auto" :class="$style['VQuiz']" v-if="!loading">
    <quiz-header :title="quizTitle" :expanded="expandedHeader" />

    <quiz-intro v-if="showIntro" @start="start" :themesCount="diagnostic.quizzesToAtemptCount" />

    <component
      v-if="showThemeIntro && quizToAnswer.quizType !== LearningEnums.QuizType.DIAG"
      :is="
        quizToAnswer.quizType === LearningEnums.QuizType.PRE
          ? QuizPreGrainIntro
          : QuizPostGrainIntro
      "
      :task="mission.getTaskByQuizId(Number($route.params.quizId))"
      :type="quizToAnswer.quizType"
      @start="startGrainQuiz()"
    />

    <quiz-grain-end
      v-if="showEndGrainQuiz && quizToAnswer.quizType !== LearningEnums.QuizType.DIAG"
      :task="mission.getTaskByQuizId(Number($route.params.quizId))"
      :mission="mission"
      :type="quizToAnswer.quizType"
    />

    <quiz-post-grain-task-result
      v-if="showTaskResult"
      :task="mission.getTaskByQuizId(Number($route.params.quizId))"
      :mission="mission"
      :quiz="quizToAnswer"
      @next-task-quiz="(nextTask: Task) => handleNextTaskQuiz(nextTask)"
    />

    <quiz-theme-intro
      :theme-title="quizToAnswer.moduleTitle"
      :questions-count="quizToAnswer.questions.length"
      :quiz-order="quizToAnswer.order"
      v-if="showThemeIntro && quizToAnswer.quizType === LearningEnums.QuizType.DIAG"
      @start="startDiagnosticQuiz(quizToAnswer)"
    />

    <quiz-content
      v-if="
        quizToAnswer.isQuizStarted &&
        !showIntro &&
        !showThemeIntro &&
        !showDiagResults &&
        !showEndGrainQuiz
      "
      :quiz="quizToAnswer"
      :diagnostic="diagnostic"
      :mission="mission"
      @end-quiz="handleEndQuiz()"
    />

    <quizzes-results
      v-if="
        showDiagResults && !showIntro && !showThemeIntro && !showEndGrainQuiz && !showTaskResult
      "
    />
  </div>
</template>

<style module lang="scss">
.VQuiz {
  background: var(--linear-02);
  position: relative;
}
</style>
