import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'
import type { IUser } from '@/assets/ts/types/UserType'
import type { ISignupEmailInput, ISignupLookupInput } from '@/assets/ts/DTO/signup/signup.dto'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/ts/classes/Error'

export const useSignupStore = defineStore('Signup', () => {
  // STATE
  const loading = ref(false)
  const hasError = ref(false)
  const errorType = ref('')
  const currentUser: Ref<IUser> = ref({} as IUser)
  const linkToRenewPassword = ref('')

  // ACTIONS
  function $reset() {
    currentUser.value = {} as IUser
    linkToRenewPassword.value = ''
  }

  /**
   * Try to find a user by school code, firstname and lastname
   * @param payload
   */
  async function lookupUser(payload: ISignupLookupInput) {
    try {
      loading.value = true
      hasError.value = false
      const response = await nabooApi.lookupForSignup(payload)
      if (response !== null) {
        currentUser.value = response
      }
      return Promise.resolve()
    } catch (error) {
      if (error instanceof NabooError) {
        currentUser.value = {} as IUser
        hasError.value = true
        if (error?.name === 'UserNotFoundException') {
          errorType.value = 'NOT_FOUND'
        } else if (error?.name === 'UserAlreadyRegisteredException') {
          errorType.value = 'ALREADY_REGISTERED'
        } else {
          errorType.value = 'UNKNOWN'
        }
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * Confirm the email of a user and return a link to renew the password
   * @param payload
   */
  async function confirmEmail(payload: ISignupEmailInput) {
    try {
      loading.value = true
      hasError.value = false
      payload.identifier = currentUser.value.identifier ?? ''
      payload.appCode = 'FRONT_APP'
      const response = await nabooApi.confirmEmailForSignup(payload)
      if (response !== null) {
        linkToRenewPassword.value = response.link
      }
      return Promise.resolve()
    } catch (error) {
      if (error instanceof NabooError) {
        linkToRenewPassword.value = ''
        hasError.value = true
      }
    } finally {
      loading.value = false
    }
  }

  return {
    // STATE
    loading,
    hasError,
    errorType,
    currentUser,
    linkToRenewPassword,

    // ACTIONS
    $reset,
    lookupUser,
    confirmEmail
  }
})
