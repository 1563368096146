import '@/assets/styles/styles.css'
import PrimeVue from 'primevue/config'

import type { App } from 'vue'

import Tooltip from 'primevue/tooltip'
import { createPinia } from 'pinia'
import Vue3Lottie from 'vue3-lottie'
import * as Sentry from '@sentry/vue'
import ConfirmationService from 'primevue/confirmationservice'
import router from '@/router'

/* import Auth0 plugin */
import auth0Client from '@/plugins/auth0'

import { primeVuePreset } from '@/plugins/primeVue'
import { registerIconsToLibrary } from '@/plugins/icons'

export function registerPlugins(app: App) {
  app.use(createPinia())
  app.use(router)

  /* add icons to the library */
  registerIconsToLibrary()

  /* Register PrimeVues's options */
  app.use(PrimeVue, {
    ripple: true,
    theme: {
      preset: primeVuePreset,
      options: {
        cssLayer: true
      }
    }
  })
  app.directive('tooltip', Tooltip)
  app.use(ConfirmationService)

  /* Register Vue3Lottie component */
  app.use(Vue3Lottie, { name: 'Vue3Lottie' })

  /* Register Auth0 plugin */
  app.use(auth0Client)

  /* Init Sentry */
  Sentry.init({
    app,
    dsn: __SENTRY_DSN__,
    environment: __ENV_NAME__,
    release: `${__APP_NAME__}@${__APP_VERSION__}`,
    tracingOptions: {
      trackComponents: ['VTraining', 'VGrain'],
      hooks: ['mount', 'update'],
      timeout: 500
    },
    integrations: [
      Sentry.browserTracingIntegration({ router, routeLabel: 'name' }),
      Sentry.replayIntegration()
    ],
    tracePropagationTargets: ['localhost', __BASE_URL_API__, 'https://.*.genial.ly/.*'],
    // Performance Monitoring
    tracesSampleRate: __SENTRY_TRACE_SAMPLE_RATE__ ?? 0.1,
    // Session Replay
    replaysSessionSampleRate: __SENTRY_REPLAY_SESSION_SAMPLE_RATE__ ?? 0.1,
    replaysOnErrorSampleRate: __SENTRY_REPLAY_ERROR_SAMPLE_RATE__ ?? 0.1
  })
}
