import type { Cacheable, ICache } from '@auth0/auth0-vue'

export class CustomAuth0SessionCache implements ICache {
  /**
   * Stocke une entrée dans le sessionStorage.
   * @param key La clé sous laquelle stocker l'entrée.
   * @param entry L'entrée à stocker.
   * @returns Une promesse résolue après le stockage.
   */
  async set<T = Cacheable>(key: string, entry: T): Promise<void> {
    try {
      const stringValue = JSON.stringify(entry)
      sessionStorage.setItem(key, stringValue)
    } catch (error) {
      console.error('Failed to store item in sessionStorage:', error)
    }
  }

  /**
   * Récupère une entrée depuis le sessionStorage.
   * @param key La clé de l'entrée à récupérer.
   * @returns Une promesse résolue avec l'entrée correspondante ou `undefined` si non trouvée.
   */
  async get<T = Cacheable>(key: string): Promise<T | undefined> {
    try {
      const item = sessionStorage.getItem(key)
      return item ? JSON.parse(item) : undefined
    } catch (error) {
      console.error('Failed to retrieve item from sessionStorage:', error)
      return undefined
    }
  }

  /**
   * Supprime une entrée du sessionStorage.
   * @param key La clé de l'entrée à supprimer.
   * @returns Une promesse résolue après la suppression.
   */
  async remove(key: string): Promise<void> {
    try {
      sessionStorage.removeItem(key)
    } catch (error) {
      console.error('Failed to remove item from sessionStorage:', error)
    }
  }

  /**
   * Récupère toutes les clés actuellement présentes dans le sessionStorage.
   * @returns Une promesse résolue avec une liste de toutes les clés.
   */
  async allKeys(): Promise<string[]> {
    try {
      return Object.keys(sessionStorage)
    } catch (error) {
      console.error('Failed to retrieve all keys from sessionStorage:', error)
      return []
    }
  }
}
